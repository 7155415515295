<template>
  <section class="auth-wrapper">
    <v-card max-width="500">
      <v-card-title class="d-flex justify-center">Зареєструватися</v-card-title>
      <alert v-if="alert" :alert-data="alert"/>
      <v-card-text>
        <v-form
          ref="registerForm"
          v-model="valid"
          @submit.prevent="submitSignUp"
          lazy-validation
        >
          <v-row>
            <v-col class="pb-0" cols="12" sm="6" md="6">
              <v-text-field
                dense
                outlined
                v-model.trim="user.firstName"
                ref="firstName"
                :rules="[rules.required]"
                label="Iм'я*"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col class="pb-0" cols="12" sm="6" md="6">
              <v-text-field
                dense
                outlined
                v-model.trim="user.lastName"
                :rules="[rules.required]"
                label="Прізвище*"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col class="pb-0 pt-0" cols="12">
              <v-text-field
                dense
                outlined
                v-model.trim="user.email"
                :rules="[rules.required, ...rules.email]"
                label="E-mail*"
                required
              ></v-text-field>
            </v-col>

            <v-col class="pb-0 pt-0" cols="12">
              <v-text-field
                dense
                outlined
                v-model.trim="user.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                label="Пароль*"
                counter
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
            <v-col class="pb-0 pt-0" cols="12">
              <v-text-field
                dense
                outlined
                block
                v-model.trim="confirmPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordMatch]"
                :type="show ? 'text' : 'password'"
                label="Підтвердити пароль*"
                counter
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-center pa-0 mb-3" cols="12">
              <v-btn :disabled="!valid" color="success" type="submit"
              >Зареєструватися
              </v-btn
              >
            </v-col>
          </v-row>
        </v-form>
<!--        <social-login/>-->
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import Alert from '@/shared/components/Alert'
import { mapActions, mapGetters } from 'vuex'
// import SocialLogin from '../components/SocialLogin'

export default {
  name: 'Register',
  components: { Alert },
  data: () => ({
    valid: true,
    user: {},
    show: false,
    confirmPassword: null,
    rules: {
      required: (value) => !!value || "Обов'язковe поле",
      min: (v) => (v && v.length >= 8) || 'Мінимум 8 символів',
      email: [
        (v) => !!v || "Обов'язковe поле",
        (v) => /.+@.+\..+/.test(v) || 'E-mail має бути дійсним'
      ]
    },
    alert: {}
  }),
  computed: {
    ...mapGetters('auth', ['isAuthed']),
    ...mapGetters('user', ['getLoginUser']),
    passwordMatch () {
      return (
        this.user.password === this.confirmPassword ||
        'Паролі повинні збігатися'
      )
    }
  },
  watch: {
    getLoginUser: {
      handler (val) {
        if (val) {
          const { email, firstName, lastName } = val.data.user
          this.$set(this.user, 'email', email)
          this.$set(this.user, 'firstName', firstName)
          this.$set(this.user, 'lastName', lastName)
          this.checkEmail(email)
            .then((res) => {
              if (res) {
                this.valid = false
                return this.setAlert({
                  status: 'error',
                  msg: 'Така пошта вже існує'
                })
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (this.isAuthed) {
      this.redirectTo('/')
    }
    this.focusInput('firstName')
  },
  methods: {
    ...mapActions('auth', ['signUp', 'logIn', 'checkEmail']),
    ...mapActions('user', ['fetchUser']),
    focusInput (name) {
      this.$refs[name].focus()
    },
    submitSignUp () {
      if (this.$refs.registerForm.validate()) {
        this.signUp(this.user)
          .then(() => {
            this.logIn({
              email: this.user.email,
              password: this.user.password
            })
            this.redirectTo('/')
          })
          .catch((err) => {
            err.errors &&
            err.errors.forEach((error) => {
              this.handleError(error)
            })
          })
      }
    },
    handleError (error) {
      if (error.message === 'already exists') {
        return this.setAlert({ status: 'error', msg: 'Така пошта вже існує' })
      }
      this.setAlert({ status: 'error', msg: error.message })
    },
    setAlert ({ status, msg, timeout = 5000 }) {
      this.alert = {
        status,
        msg
      }
      setTimeout(() => (this.alert = {}), timeout)
    },
    redirectTo (path) {
      const redirect = this.$route.query.redirect || path
      const query = Object.fromEntries(
        Object.entries(this.$route.query).filter((el) => el[0] !== 'redirect')
      )
      this.$router.push({ path: redirect, query }).catch(() => {
      })
    }
  }
}
</script>

<style scoped></style>
